import { Box } from "@mui/material";
import { FadeIn } from "react-slide-fade-in";

const LoginPage = () => {

    return(
        <Box paddingTop={25} display="flex" justifyContent="space-evenly">

            <FadeIn from="left" positionOffset={50} triggerOffset={0} delayInMilliseconds={100}>
                <img alt="loginpage" src="assets/login1.jpg" />
            </FadeIn>

            <FadeIn from="left" positionOffset={50} triggerOffset={0} delayInMilliseconds={300}>
                <img alt="loginpage" src="assets/login2.jpg" />
            </FadeIn>
                
            <FadeIn from="left" positionOffset={50} triggerOffset={0} delayInMilliseconds={600}>
                <img alt="loginpage" src="assets/login3.jpg" />
            </FadeIn>

        </Box>
    )
}

export default LoginPage