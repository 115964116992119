import { createTheme } from "@mui/material"
import { useMemo } from "react"

export const tokens = () => ({
    primary: {
          100: "#fdefd9",
          200: "#fbdfb3",
          300: "#f9d08d",
          400: "#f7c067",
          500: "#FFFFFF",
          600: "#c48d34",
          700: "#936a27",
          800: "#62461a",
          900: "#31230d"
    },
})

export const themeSettings = () => {
    
    const colors = tokens()

    return{
        palette:{
            primary:{main: colors.primary[500]},
            secondary:{main: colors.primary[900]},
            background:{default: colors.primary[500]}
        },

        typography:{
            fontFamily:["Back_to_Black", "sans-serif"].join(","),
            fontSize:12,

            h1:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 40 },
            h2:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 32 },
            h3:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 24 },
            h4:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 20 },
            h5:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 16 },
            h6:{ fontFamily: ["American_Captain", "sans-serif"].join(","), fontSize: 14 },
        }
    }
}

export const useCustomTheme = () => {
    const theme = useMemo(() => createTheme(themeSettings()),[])

    return [theme]
}