import { Box } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { Typography } from "@mui/material";
import { FadeIn } from "react-slide-fade-in";

const TopBar = () => {

    const {loginWithRedirect, logout, isAuthenticated} = useAuth0();

    return(
        <Box>
            
            <Box display="flex" sx={{ backgroundColor:"#ffd24d" }} paddingTop={1} paddingBottom={1}>
                {isAuthenticated ? (
                    <Button style={{ marginLeft:"auto" }} onClick={() => logout()}>
                        <Typography color="white" variant="h3">LOGOUT</Typography>
                        <LogoutRoundedIcon sx={{ color:"white", textAlign:"center" }} fontSize="large"/>
                    </Button>
                ):(
                    <Button style={{ marginLeft:"auto" }} onClick={() => loginWithRedirect()}>
                        <Typography color="white" variant="h3">LOGIN</Typography>
                        <LoginIcon sx={{ color:"white", textAlign:"center" }} fontSize="large"/>
                    </Button>
                )}
            </Box>
            
            <Box textAlign="Center" paddingTop={0}>
                <FadeIn from="left" positionOffset={0} triggerOffset={0} delayInMilliseconds={1000}>
                    <img alt="boost360" src="https://s3-ap-southeast-1.amazonaws.com/withfloats-content-cdn/PartnerImages/aec/AECLogo.jpg" />
                </FadeIn>
            </Box>
        </Box>
    )
}

export default TopBar