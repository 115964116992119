import {Box} from "@mui/material"
import {Typography} from "@mui/material"
import { Link } from "react-router-dom"
import { Carousel } from "react-bootstrap"
import { useAuth0 } from "@auth0/auth0-react";

const Dashboard = ({user}) => {

    const {logout} = useAuth0();

    const logoutUnAuthorized = () => {
        logout()
    }

    if (user["email"].includes("@nowfloats.com")){
    
        return(
            <Box textAlign="center" height="79vh">

                <Box>
                    <Link to="/bizapp">
                        <Box sx={{ backgroundColor:"#ffd24d" }} className="dib br3 pa2 ma3 bw2 grow shadow-5">
                            <Typography fontSize="35px" textAlign="center" color="white" variant="h1" paddingTop={1}>BIZ APP (Under Construction)</Typography>
                        </Box>
                    </Link>

                    <Link to="/sslactivatition">
                        <Box sx={{ backgroundColor:"#ffd24d" }} className="dib br3 pa2 ma3 bw2 grow shadow-5">
                            <Typography fontSize="35px" textAlign="center" color="white" variant="h1" paddingTop={1}>SSL ACTIVATITION</Typography>
                        </Box>
                    </Link>
                </Box>
                
                <Box marginTop={5} display="flex" justifyContent="space-around">
                    <Carousel>
                        <Carousel.Item>
                            <img width="900px" src="assets/nf1.jpg" alt="First slide" />
                        </Carousel.Item>

                        <Carousel.Item>
                            <img width="900px"  src="assets/nf2.jpg" alt="Second slide" />
                        </Carousel.Item>
                
                        <Carousel.Item>
                            <img width="900px"  src="assets/nf3.jpg" alt="Third slide" />
                        </Carousel.Item>
                    </Carousel>
                </Box>

            </Box>
        )
    }else{
        return(
            <Box>
                <Typography paddingTop={35} fontSize={100} color="#ECECEC" textAlign="center" variant="h1">UNAUTHORIZED</Typography>
                {logoutUnAuthorized()}
            </Box>
        )
    }

}

export default Dashboard