import { Typography } from "@mui/material"
import {Box} from "@mui/material"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormik } from "formik";
import axios from "axios";

const SSLActivatition = ({user}) => {

    const [activateSubmit] = useState(true)
    const [status,setstatus] = useState([])
    const {logout} = useAuth0();

    const formik = useFormik({
        initialValues:{
            DOMAINNAME: "",
            FPTAG: "",
        },
        onSubmit: (values) => {
            console.log(values.DOMAINNAME)
            console.log(values.FPTAG)

            axios.post("https://jiw-customeractivatition-api-as-prod.azurewebsites.net/createCertificate/647a5ca373587461729a058e",{
                    DOMAINNAME: values.DOMAINNAME,
                    FPTAG: values.FPTAG,
                },{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
        }
    })

    const logoutUnAuthorized = () => {
        logout()
    }

    const fetchData = async() => {
        const result = await fetch('https://jiw-customeractivatition-api-as-prod.azurewebsites.net/getstatus/647a5ca373587461729a058e')
        const jsonResult = await result.json()

        setstatus(jsonResult)

        console.log("Refreshed")
    }

    useEffect(() => {
        fetchData()
    },[])

    const CheckStatus = () => {

        for (let i = 0; i < 180; i++){
            setTimeout(function() {
                console.log(i)
                fetchData()
            }, i * 2000);
        }
    }

    if (user["email"].includes("@nowfloats.com")){
        if(status["startCheck"] === false){
            return(
                <Box>
                    <Box display="flex" justifyContent="space-around">
                        <Box sx={{ backgroundColor:"#EAEAEA", padding:"2.6% 10% 2.6% 10%"}} className="dib br3 pa2 ma3 bw2">
    
                            <Typography paddingBottom={3} fontSize="50px" textAlign="center" color="#BDBDBD" variant="h1" >SSL Activation</Typography>
    
                            <Form onSubmit={formik.handleSubmit} method="POST" encType="multipart/form-data">
        
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Box>
                                        <Box>
                                            <Form.Control type="text" name="DOMAINNAME" placeholder="Domain name" onChange={formik.handleChange} value={formik.values.DOMAINNAME} required/>
                                        </Box>
                                        <Box paddingTop={1}>
                                            <Form.Control type="text" name="FPTAG" placeholder="FP Tag" onChange={formik.handleChange} value={formik.values.FPTAG} required/>
                                        </Box>
                                    </Box>
                                </Form.Group>
    
                                <Box display="flex" justifyContent="space-evenly">
                                <Box>
                                    { activateSubmit ? <Button variant="success" type="submit" onClick={CheckStatus}><PublishRoundedIcon />Submit</Button> : <img width="30px" alt="loading" src="./assets/loading.gif" /> }
                                </Box>
                                <Box>
                                    <Link to="/">
                                        <Button variant="danger" type="submit"><ArrowBackIosNewRoundedIcon />Back</Button>
                                    </Link>
                                </Box>
                                </Box>
        
                            </Form>
        
                            </Box>
                        </Box>
                    </Box>
            )
        }else{
            return(
                <Box>
                    <Box display="flex" justifyContent="space-around">
                        <Box sx={{ backgroundColor:"#EAEAEA", textAlign:"center", padding:"3.2% 6% 3.3% 6%" }} className="dib br3 pa2 ma3 bw2">
                            
                            <h1>{status["domainname"]}</h1><br/>
    
                        <Box display="flex" justifyContent="space-around">
    
                            <Box className="dib pa2 ma2">
                                <h6>SSL Creatition</h6>
                                {status["sslcreatition"] ? <img width="40px" alt="loading" src="assets/done.gif"/> : <img width="60px" alt="loading" src="assets/loading.gif"/>}
                            </Box>
    
                            <Box className="dib pa2 ma2">
                                <h6>Validatition</h6>
                                {status["sslverification"] ? <img width="40px" alt="loading" src="assets/done.gif"/> : <img width="60px" alt="loading" src="assets/loading.gif"/>}
                            </Box>
    
                            <Box className="dib pa2 ma2">
                                <h6>Cloudfront</h6>
                                {status["cloudfrontcreation"] ? <img width="40px" alt="loading" src="assets/done.gif"/> : <img width="60px" alt="loading" src="assets/loading.gif"/>}
                            </Box>

                            {/* <Box className="dib pa2 ma2">
                                <h6>Activatition</h6>
                                {status["cloudfrontcreation"] ? <img width="40px" alt="loading" src="assets/done.gif"/> : <img width="60px" alt="loading" src="assets/loading.gif"/>}
                            </Box> */}
                            
                        </Box>

                            <Button variant="danger" type="submit" onClick={CheckStatus}>Refresh status</Button>
    
                        </Box>
                    </Box>

                    <Typography paddingTop={3} textAlign="center" color="#BDBDBD" variant="h4" >Note: Sometimes "SSL Validatition" could take upto 15 minutes</Typography>
                </Box>
            )
        }
    }else{
        return(
            <Box>
                <Typography paddingTop={35} fontSize={100} color="#ECECEC" textAlign="center" variant="h1">UNAUTHORIZED</Typography>
                {logoutUnAuthorized()}
            </Box>
        )
    }
}

export default SSLActivatition