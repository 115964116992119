import { Typography } from "@mui/material"
import {Box} from "@mui/material"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Bizapp = ({user}) => {

    const {logout} = useAuth0();

    const logoutUnAuthorized = () => {
        logout()
    }

    if (user["email"].includes("@nowfloats.com")){
        return(
            <Box>
                <Box display="flex" justifyContent="space-around">
                    <Box sx={{ backgroundColor:"#EAEAEA", padding:"2.6% 9.6% 2.5% 9.5%"}} className="dib br3 pa2 ma3 bw2">
    
                        <Typography paddingBottom={3} fontSize="50px" textAlign="center" color="#BDBDBD" variant="h1" >BIZ APP</Typography>
    
                        <Form action="http://localhost:8000/createCertificate" method="POST" enctype="multipart/form-data">
        
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="Domain name" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="FPTag" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="Domain name" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="FPTag" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="Domain name" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="FPTag" name="DOMAINNAME" required/>
                                    </Box>
    
                                    <Box>
                                        <Form.Control type="text" id="DOMAINNAME" placeholder="FPTag" name="DOMAINNAME" required/>
                                    </Box>
    
                                </Box>
                            </Form.Group>
    
                            <Box display="flex" justifyContent="space-evenly">
                                <Box>
                                    <Button paddingRight="" variant="success" type="submit"><PublishRoundedIcon />Submit</Button>
                                </Box>
                                <Box>
                                    <Link to="/">
                                        <Button variant="danger" type="submit"><ArrowBackIosNewRoundedIcon />Back</Button>
                                    </Link>
                                </Box>
                            </Box>
        
                        </Form>
        
                    </Box>
                </Box>
            </Box>
        )
    }else{
        return(
            <Box>
                <Typography paddingTop={35} fontSize={100} color="#ECECEC" textAlign="center" variant="h1">UNAUTHORIZED</Typography>
                {logoutUnAuthorized()}
            </Box>
        )
    }
}

export default Bizapp