import { ThemeProvider } from "@emotion/react";
import { useCustomTheme } from "./theme";
import { CssBaseline, Typography } from "@mui/material";
import Dashboard from "./dashboard/dashboard";
import Bizapp from "./products/bizapp";
import SSLActivatition from "./products/sslactivatition";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import TopBar from "./global/topbar";
import LoginPage from "./components/loginPage";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";


function App() {

  const [theme] = useCustomTheme()
  const {isLoading, error, isAuthenticated, user} = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <div className="app">
            <main className="content">
              <TopBar />
              
              {!isAuthenticated ? (
                !error && isLoading ? (
                  <Typography paddingTop={35} fontSize={100} color="#ECECEC" textAlign="center" variant="h1">NOWFLOATS</Typography>
                ):(
                  <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/dashboard" element={<Navigate to="/" />} />
                    <Route path="/sslactivatition" element={<Navigate to="/" />} />
                    <Route path="/bizapp" element={<Navigate to="/" />} />
                  </Routes>
                )
              ):(
                <Routes>
                  <Route path="/dashboard" element={<Dashboard user={user} />} />
                  <Route path="/sslactivatition" element={<SSLActivatition user={user} />} />
                  <Route path="/bizapp" element={<Bizapp user={user} />} />
                <Route path="/" element={<Navigate to="/dashboard"/>} />
              </Routes>
                )
              }

            </main>
        </div>
      </CssBaseline>
    </ThemeProvider>
    )
}

export default App;